<template>
	<canvas></canvas>
</template>
<script type="text/javascript">
	import Chart from 'chart.js/auto';
	export default {
		props: {
			type: {
				type: String,
				required: true
			},
			data: {
				type: Object,
				required: true
			},
			options: {
				type: Object,
				required: false
			}
		},
		mounted(){
			//Chart.defaults.global.elements.point.radius = 0
			//Chart.defaults.global.elements.point.pointStyle = 'line'
			var ctx = this.$el
			new Chart(ctx, {
				type: this.type,
				data: this.data,
				options: this.options
			})
		}
	}
</script>